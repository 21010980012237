import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
    DeviceMobileIcon,
    ScissorsIcon,
    UserIcon,
} from '@heroicons/react/outline'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    LineController,
    ArcElement,
} from 'chart.js'

import { Line, Bar, Pie } from 'react-chartjs-2'

import type { UseQueryResult } from 'react-query'
import type {
    ProtectionFilmTypeListResponse,
    ResponseData,
    ResponseList,
    StatisticsCompareResponse,
    StatisticsResponse,
} from '../api/types'
import {
    barChartOptions,
    barChartPrintOptions,
    barChartFromSerialOptions,
    pieOptions,
    transposedBarChartOptions,
    seriesChartOptions,
    seriesChartPrintOptions,
    piePrintOptions,
    transposedBarChartPrintOptions,
} from '../config/chartOptions'
import {
    Button,
    DetailedUsageCounters,
    Loader,
    UsageCounter,
    UsageCounterContainer,
} from './ui'
import { StatisticsFilterState } from 'types'
import moment from 'moment'
import { useIsPrinting } from 'hooks/useIsPrinting'
import { MultipleColourGradients } from 'helpers/colourGradient'

const colors = [
    '#9030CA',
    '#34E8A3',
    '#FCA5A5',
    '#991B1B',
    '#FDE68A',
    '#FBBF24',
    '#6EE7B7',
    '#34D399',
    '#0EA5E9',
    '#67E8F9',
    '#818CF8',
    '#F97316',
    '#C2410C',
    '#A5B4FC',
    '#4C1D95',
    '#F43F5E',
    '#9C69BA',
    '#30CAB8',
    '#827638',
    '#0085FF',
    '#FF8D07',
    '#2563EB',
    '#A78BFA',
    '#5B21B6',
]

type StatisticsProps = {
    statisticsQuery: UseQueryResult<ResponseData<StatisticsResponse>>
    protectionFilmsTypesQuery: UseQueryResult<
        ResponseList<ProtectionFilmTypeListResponse>
    >
    statisticsCompareQuery?: UseQueryResult<
        ResponseData<StatisticsCompareResponse>
    >
    isUser: boolean
    isSupervisor: boolean
    filters: StatisticsFilterState
}

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineController,
    LineElement,
    ArcElement
)

function generateDateLabels(dates: string[]): string[] {
    if (dates.length === 0) {
        return []
    }

    const regex = /^\d{4}-\d{2}-\d{2}$/g
    if (dates[0].match(regex)) {
        return dates.map((date) => moment(date).format('DD-MM'))
    }

    return dates.map((date) => moment(date).format('MM'))
}

const Statistics: React.FC<StatisticsProps> = ({
    statisticsQuery,
    protectionFilmsTypesQuery,
    statisticsCompareQuery,
    filters,
    isUser,
    isSupervisor,
}) => {
    const { t } = useTranslation()
    const isPrinting = useIsPrinting()

    const clientSeries = useMemo(() => {
        const data = statisticsQuery.data?.data.clients ?? []

        return data.map(({ name, count }) => ({
            label: name,
            data: count,
        }))
    }, [statisticsQuery.data?.data])

    const brandsSeries = useMemo(() => {
        const data = statisticsQuery.data?.data.general_summary.brands ?? []

        return data.map(({ name, count }) => ({
            label: name,
            data: count,
        }))
    }, [statisticsQuery.data?.data.general_summary.brands])

    const typeSeries = useMemo(() => {
        const data = statisticsQuery.data?.data.protection_films.data ?? []

        return Object.entries(data).map(([key, val]) => {
            return {
                label:
                    protectionFilmsTypesQuery.data?.data.find(
                        ({ id }) => id.toString() === key
                    )?.name || '',
                data: val,
            }
        })
    }, [
        statisticsQuery.data?.data.protection_films,
        protectionFilmsTypesQuery.data?.data,
    ])

    const cuttersSeries = useMemo(() => {
        const actualCutters =
            statisticsCompareQuery?.data?.data.actual_cutters ?? []
        const comparedCutters =
            statisticsCompareQuery?.data?.data.compared_cutters ?? []
        const duration =
            moment(filters.to).diff(moment(filters.from), 'days') + 1
        const from = moment(filters.from)
            .subtract(duration, 'days')
            .format('DD-MM')
        const to = moment(filters.to).subtract(duration, 'days').format('DD-MM')

        return [
            {
                label: t(`cutters_series.actual_cuters`),
                data: actualCutters,
            },
            {
                label: t(`cutters_series.compared_cuters`, { from, to }),
                data: comparedCutters,
            },
        ]
    }, [statisticsCompareQuery?.data?.data, t, filters.from, filters.to])

    const cuttersSeriesLabels = useMemo(
        () =>
            generateDateLabels(statisticsCompareQuery?.data?.data.dates || []),
        [statisticsCompareQuery?.data?.data.dates]
    )

    const foilUsageLabels = useMemo(
        () =>
            generateDateLabels(
                statisticsQuery.data?.data.protection_films.dates || []
            ),
        [statisticsQuery.data?.data.protection_films.dates]
    )

    return (
        <div className="mt-4">
            {!isUser && (
                <>
                    <h5 className="text-gray-200 py-6 text-lg leading-8 font-medium border-t border-gray-600 print:text-black">
                        {t('statistics.bar_chart.title')}
                    </h5>
                    <TopColumnChart
                        series={clientSeries}
                        isPrinting={isPrinting}
                    />
                    <span className="print:hidden">
                        <Button as="link" to="/report" variant="secondary">
                            {t('statistics.data_link')}
                        </Button>
                    </span>
                </>
            )}
            {isUser && (
                <>
                    <h5 className="text-gray-200 py-6 text-lg leading-8 font-medium border-t border-gray-600 print:text-black">
                        {t('statistics.brands_chart.title')}
                    </h5>
                    <TopColumnChart
                        series={brandsSeries}
                        isPrinting={isPrinting}
                    />
                    <span className="print:hidden">
                        <Button as="link" to="/report" variant="secondary">
                            {t('statistics.data_link')}
                        </Button>
                    </span>
                </>
            )}
            {statisticsCompareQuery && (
                <>
                    {statisticsCompareQuery.isLoading ? (
                        <Loader />
                    ) : (
                        <>
                            <h5 className="text-gray-200 py-6 text-lg leading-8 font-medium border-t border-gray-600 mt-12 print:text-black">
                                {t('statistics.cutters_chart.title')}
                            </h5>
                            <CuttersSeriesChart
                                isPrinting={isPrinting}
                                series={cuttersSeries}
                                categories={cuttersSeriesLabels}
                            />
                        </>
                    )}
                </>
            )}
            <h5 className="text-gray-200 py-6 text-lg leading-8 font-medium border-t border-gray-600 mt-12 print:text-black">
                {t('statistics.series_chart.title')}
            </h5>
            <FoilUsageSeriesChart
                isPrinting={isPrinting}
                series={typeSeries}
                categories={foilUsageLabels}
            />
            {statisticsQuery.data?.data.general_summary ? (
                <>
                    <h5 className="text-gray-200 py-6 text-lg leading-8 font-medium border-t border-gray-600 print:text-black pagebreak">
                        {t('statistics.summary.title')}
                    </h5>
                    <SummaryRow
                        data={statisticsQuery.data?.data.general_summary}
                        isUser={isUser}
                    />
                    <FoilUsage
                        data={statisticsQuery.data?.data.general_summary}
                    />
                    <div className="w-full my-12 flex justify-between gap-6 print:grid print:grid-cols-2 pagebreak flex-col lg:flex-row">
                        <PieChart
                            isPrinting={isPrinting}
                            series={
                                statisticsQuery.data?.data.general_summary
                                    .devices_types
                            }
                        />
                        {!isUser && (
                            <TopBrandsChart
                                isPrinting={isPrinting}
                                series={
                                    statisticsQuery.data?.data.general_summary
                                        .brands
                                }
                            />
                        )}
                        <TopDevicesChart
                            isPrinting={isPrinting}
                            series={
                                statisticsQuery.data?.data.general_summary
                                    .devices
                            }
                        />
                    </div>
                </>
            ) : null}
        </div>
    )
}

type TopColumnChartProps = {
    series: { label: string; data: number }[]
    isPrinting: boolean
}

const TopColumnChart: React.FC<TopColumnChartProps> = ({ series }) => {
    const BarChart = useMemo(() => {
        const colorGen = MultipleColourGradients(
            colors.map((col) => col.replace('#', '')),
            0,
            series.length
        )
        const pregenColors = [...new Array(series.length)].map((_, index) => {
            return colorGen.colourAt(
                (index + (index % 2) * Math.floor(series.length / 3)) %
                    series.length
            )
        })

        const data = {
            labels: series.map((serie) => serie.label),
            datasets: [
                {
                    label: series.length > 0 ? series[0].label : '',
                    data: series.map((serie) => serie.data),
                    backgroundColor: pregenColors,
                    borderColor: pregenColors,
                    borderWidth: 0,
                    barPercentage: 1.0,
                    categoryPercentage: 0.6,
                    skipNull: true,
                },
            ],
        }

        return (
            <div className="relative">
                <div className="h-[250px] md:h-[358px] mb-6 print:hidden">
                    <Bar options={barChartOptions} data={data} />
                </div>
                <div className="h-[250px] md:h-[358px] mb-6 print:w-[755px] absolute invisible print:visible print:static">
                    <Bar options={barChartPrintOptions as any} data={data} />
                </div>
            </div>
        )
    }, [series])

    return BarChart
}

type FoilUsageSeriesChartProps = {
    series: { label: string; data: number[] }[]
    isPrinting: boolean
    categories: string[]
}

const FoilUsageSeriesChart: React.FC<FoilUsageSeriesChartProps> = ({
    series,
    categories,
}) => {
    const Chart = useMemo(() => {
        const data = {
            labels: categories,
            datasets: series.map((serie, index) => ({
                label: serie.label,
                data: serie.data,
                backgroundColor: colors[index % colors.length],
                borderColor: colors[index % colors.length],
                borderWidth: 2,
                pointRadius: 0,
            })),
        }

        if (series[0]?.data.length <= 2) {
            return (
                <div className="relative">
                    <div className="h-[250px] md:h-[358px] mb-12 print:hidden">
                        <Bar data={data} options={barChartOptions} />
                    </div>
                    <div className="h-[250px] md:h-[358px] mb-12 absolute invisible print:w-[755px] print:visible print:static">
                        <Bar
                            data={data}
                            options={barChartFromSerialOptions as any}
                        />
                    </div>
                </div>
            )
        }

        return (
            <div className="relative">
                <div className="h-[250px] md:h-[358px] mb-12 print:hidden">
                    <Line data={data} options={seriesChartOptions} />
                </div>
                <div className="h-[250px] md:h-[358px] mb-12 absolute invisible print:w-[755px] print:visible print:static">
                    <Line
                        data={data}
                        options={seriesChartPrintOptions as any}
                    />
                </div>
            </div>
        )
    }, [series, categories])

    return Chart
}

type CuttersSeriesChartProps = {
    series: { label: string; data: number[] }[]
    isPrinting: boolean
    categories: string[]
}

const CuttersSeriesChart: React.FC<CuttersSeriesChartProps> = ({
    series,
    categories,
}) => {
    const Chart = useMemo(() => {
        const data = {
            labels: categories,
            datasets: series.map((serie, index) => ({
                label: serie.label,
                data: serie.data,
                backgroundColor: colors[index % colors.length],
                borderColor: colors[index % colors.length],
                segment:
                    index === 1
                        ? {
                              borderDash: (ctx: any) => [6, 6],
                          }
                        : undefined,
                borderWidth: 2,
                pointRadius: 0,
            })),
        }

        if (series[0]?.data.length <= 2) {
            return (
                <div className="relative">
                    <div className="h-[250px] md:h-[358px] mb-12 print:hidden">
                        <Bar data={data} options={barChartOptions} />
                    </div>
                    <div className="h-[250px] md:h-[358px] mb-12 absolute invisible print:w-[755px] print:visible print:static">
                        <Bar
                            data={data}
                            options={barChartFromSerialOptions as any}
                        />
                    </div>
                </div>
            )
        }

        return (
            <div className="relative">
                <div className="h-[250px] md:h-[358px] mb-12 print:hidden">
                    <Line data={data} options={seriesChartOptions} />
                </div>
                <div className="h-[250px] md:h-[358px] mb-12 absolute invisible print:w-[755px] print:visible print:static">
                    <Line
                        data={data}
                        options={seriesChartPrintOptions as any}
                    />
                </div>
            </div>
        )
    }, [series, categories])

    return Chart
}

const SummaryRow: React.FC<{
    data: StatisticsResponse['general_summary']
    isUser: boolean
}> = ({ data, isUser }) => {
    const { t } = useTranslation()

    return (
        <div className="my-12">
            <UsageCounterContainer>
                <UsageCounter
                    Icon={DeviceMobileIcon}
                    value={data.totals[0].protection_films_count}
                    label={t('statistics.totals.protection_films_count')}
                />
                <UsageCounter
                    Icon={ScissorsIcon}
                    value={data.totals[0].cutters_count}
                    label={t('statistics.totals.cutters_count')}
                />
                {!isUser && (
                    <UsageCounter
                        Icon={UserIcon}
                        value={data.totals[0].clients_count}
                        label={t('statistics.totals.clients_count')}
                    />
                )}
            </UsageCounterContainer>
        </div>
    )
}

const FoilUsage: React.FC<{ data: StatisticsResponse['general_summary'] }> = ({
    data,
}) => {
    return (
        <div className="my-12">
            <DetailedUsageCounters
                protectionFilmsSizes={data.protection_films_sizes}
                protectionFilmsTypes={data.protection_films_types}
            />
        </div>
    )
}

const PieChart: React.FC<{
    series: StatisticsResponse['general_summary']['devices_types']
    isPrinting: boolean
}> = ({ series, isPrinting }) => {
    const { t } = useTranslation()

    const Chart = useMemo(() => {
        const data = {
            labels: series.map((series) => t(`device_type.${series.type}`)),
            datasets: [
                {
                    label: '',
                    data: series.map((series) => series.count),
                    backgroundColor: colors,
                    borderColor: isPrinting ? '#ffffff' : '#111827',
                },
            ],
        }

        return (
            <div className="relative">
                <div className="h-[261px] print:hidden">
                    <Pie data={data} options={pieOptions} />
                </div>
                <div className="h-[261px] absolute invisible w-[317px] print:visible print:static">
                    <Pie data={data} options={piePrintOptions as any} />
                </div>
            </div>
        )
    }, [series, isPrinting, t])

    return (
        <div className="w-full mb-12 rounded-lg bg-gray-900 border-2 border-gray-700 print:border-gray-300 py-8 px-6 text-center print:mb-4">
            {Chart}
            <h3 className="text-2xl leading-8 font-semibold pt-6 print:text-black canvas-container">
                {t('statistics.pie_chart.title')}
            </h3>
        </div>
    )
}

const TopBrandsChart: React.FC<{
    series: StatisticsResponse['general_summary']['brands']
    isPrinting: boolean
}> = ({ series }) => {
    const { t } = useTranslation()

    const BarChart = useMemo(() => {
        const data = {
            labels: [''],
            datasets: series.map((serie, index) => ({
                label: serie.name,
                data: [serie.count],
                backgroundColor: colors[index % colors.length],
                borderColor: colors[index % colors.length],
                borderWidth: 0,
                barPercentage: 0.6,
                categoryPercentage: 1.0,
                skipNull: true,
            })),
        }

        return (
            <div className="relative">
                <div className="h-[261px] print:hidden">
                    <Bar
                        options={transposedBarChartOptions as any}
                        data={data}
                    />
                </div>
                <div className="h-[261px] absolute invisible w-[317px] print:visible print:static">
                    <Bar
                        options={transposedBarChartPrintOptions as any}
                        data={data}
                    />
                </div>
            </div>
        )
    }, [series])

    return (
        <div className="w-full mb-12 rounded-lg bg-gray-900 border-2 border-gray-700 print:border-gray-300 py-8 px-6 text-center print:mb-4">
            {BarChart}
            <h3 className="text-2xl leading-8 font-semibold pt-6 print:text-black canvas-container">
                {t('statistics.brands_chart.title')}
            </h3>
        </div>
    )
}

const TopDevicesChart: React.FC<{
    series: StatisticsResponse['general_summary']['devices']
    isPrinting: boolean
}> = ({ series, isPrinting }) => {
    const { t } = useTranslation()

    const BarChart = useMemo(() => {
        const data = {
            labels: [''],
            datasets: series.map((serie, index) => ({
                label: serie.name,
                data: [serie.count],
                backgroundColor: colors[index % colors.length],
                borderColor: colors[index % colors.length],
                borderWidth: 0,
                barPercentage: 0.6,
                categoryPercentage: 1.0,
                skipNull: true,
            })),
        }

        return (
            <div className="relative">
                <div className="h-[261px] print:hidden">
                    <Bar
                        options={transposedBarChartOptions as any}
                        data={data}
                    />
                </div>
                <div className="h-[261px] absolute invisible w-[317px] print:visible print:static">
                    <Bar
                        options={transposedBarChartPrintOptions as any}
                        data={data}
                    />
                </div>
            </div>
        )
        /* eslint-disable-next-line */
    }, [series, isPrinting])

    return (
        <div className="w-full mb-12 rounded-lg bg-gray-900 border-2 border-gray-700 print:border-gray-300 py-8 px-6 text-center">
            {BarChart}
            <h3 className="text-2xl leading-8 font-semibold pt-6 print:text-black canvas-container">
                {t('statistics.devices_chart.title')}
            </h3>
        </div>
    )
}

export default Statistics
