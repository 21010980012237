import React, { useMemo } from 'react'
import { Form, Formik } from 'formik'
import { DialogTitle } from '@headlessui/react'

import { getCxFromStyles } from '../helpers'
import Modal from './Modal'
import { Button, Label } from './ui'

import type { DropdownItem, FormSubmitFn, IModal, ReportForm } from '../types'

import styles from './Modal.module.scss'
import { useTranslation } from 'react-i18next'
import { DropdownField, TextField } from './forms'

export type ReportModalProps = {
    modal: IModal
    onSubmit: FormSubmitFn<ReportForm>
    reportTypes: DropdownItem[]
}

export default function ReportModal({
    modal,
    onSubmit,
    reportTypes,
}: ReportModalProps) {
    const { t } = useTranslation()
    const cx = getCxFromStyles(styles)

    const types = useMemo(
        () =>
            reportTypes.map((type) => ({
                ...type,
                name: t(`periodicity.${type.name}`),
            })),
        [reportTypes, t]
    )

    return (
        <Modal modal={modal} type="default">
            <div>
                <DialogTitle
                    as="h3"
                    className="text-lg leading-6 font-medium text-white text-center"
                >
                    {t('report.on_demand.title')}
                </DialogTitle>
                <Formik<ReportForm>
                    onSubmit={onSubmit}
                    initialValues={{
                        email: '',
                    }}
                >
                    {({ isSubmitting, values, setFieldValue }) => (
                        <Form className="px-2 pb-2">
                            <div className="mt-6 grid grid-cols-3 gap-4 pb-4">
                                <div className="col-span-2">
                                    <Label>
                                        {t('form.report.field.email')}
                                    </Label>
                                    <TextField name="email" />
                                </div>
                                <div className="col-span-1">
                                    <Label>{t('form.report.field.type')}</Label>
                                    <DropdownField
                                        name="size"
                                        value={types.find(
                                            (type) =>
                                                type.id ===
                                                values.report_type?.id
                                        )}
                                        items={types}
                                        onChange={(value) =>
                                            setFieldValue('report_type', value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className={cx('action-container')}>
                                <Button
                                    tabIndex={-1}
                                    variant="secondary"
                                    type="button"
                                    onClick={modal.closeModal}
                                >
                                    {t('form.cancel')}
                                </Button>
                                <Button
                                    tabIndex={-1}
                                    variant="primary"
                                    type="submit"
                                    loading={isSubmitting}
                                    disabled={
                                        isSubmitting || !values.report_type
                                    }
                                >
                                    {t('form.send')}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
}
