import { useMutation, UseMutationOptions } from 'react-query'

import client from '../client'

import type { ResponseData, ResponseError } from '../types'

type TestVariables = {
    user: number
    email: string
    report_type_id?: number
}

const testReport = (data: TestVariables): Promise<ResponseData<{}>> =>
    client.post(`/reports/test`, data)

export const useTestReport = (
    options?: Omit<
        UseMutationOptions<ResponseData<{}>, ResponseError, TestVariables>,
        'mutationFn'
    >
) => useMutation((data) => testReport(data), options)
