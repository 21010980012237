import React, { useCallback } from 'react'
import { Form, FormikErrors } from 'formik'
import { useTranslation } from 'react-i18next'

import { Button, Checkbox, Label } from './ui'
import { TextField } from './forms'

import type { UserForm } from '../types'

import ConfirmModal from './ConfirmModal'
import EmailList from './EmailList'
import { ReportTypeListResponse } from 'api/types'

type UserProps = {
    values: UserForm
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
    ) => Promise<void | FormikErrors<UserForm>>
    isSubmitting: boolean
    readonly?: boolean
    reportTypes: ReportTypeListResponse
}

export default function UserReportSettings({
    values,
    setFieldValue,
    isSubmitting,
    readonly = false,
    reportTypes,
}: UserProps) {
    const { t } = useTranslation()

    const switchReportToggle = useCallback(
        (reportId: number) => {
            const index = values.reports.indexOf(reportId)
            setFieldValue(
                'reports',
                index !== -1
                    ? values.reports.filter((rep) => rep !== reportId)
                    : [...values.reports, reportId]
            )
        },
        [setFieldValue, values.reports]
    )

    return (
        <Form>
            <div className="mt-8">
                <div>
                    <div className="border-t border-gray-600 my-5"></div>
                    <div className="text-lg leading-8 font-medium">
                        {t('form.user.tabs.report')}
                    </div>
                    <div className="pb-5">
                        {t('form.user.report.description')}
                    </div>

                    <div className="mr-auto max-w-3xl grid grid-cols-1 md:grid-cols-2 gap-4 pb-3 pl-4">
                        {reportTypes.map(({ id, name }) => (
                            <div className="col-span-1">
                                <Checkbox
                                    name={t(`form.user.report.${name}`)}
                                    value={values.reports.indexOf(id) !== -1}
                                    onChange={() => switchReportToggle(id)}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                {/* <div className="pb-14">
                    <div className="border-t border-gray-600 pt-5"></div>
                    <div className="text-lg leading-8 font-medium">
                        {t('form.user.report.send_to')}
                    </div>
                    <div className="pb-11">
                        {t('form.user.report.send_to_description')}
                    </div>

                    <div className="mr-auto max-w-3xl grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="col-span-1">
                            <Label>{t('form.user.field.email')}</Label>
                            <TextField
                                name="email_report"
                                disabled={readonly}
                            />
                        </div>
                        <div className="col-span-1 mt-auto">
                            <Button
                                type="submit"
                                variant="primary"
                                className="ml-3"
                                loading={isSubmitting}
                            >
                                {t('form.user.add_email')}
                            </Button>
                        </div>
                    </div>
                </div> */}

                {/* <>
                    <div className="border-t border-gray-600 my-5"></div>
                    <div>
                        <span className="inline-block text-lg leading-6 font-medium pb-5">
                            {t('form.user.title.plotters')}
                        </span>
                        <EmailList
                            filters={{ search_global: '', length: 1 }}
                            filterCount={0}
                            // handleRemove={changeUserPlotterForm
                            //     ? (id) => {
                            //         changeUserPlotterForm.modal.setState(
                            //             id
                            //         )
                            //         changeUserPlotterForm.form.setValues(
                            //             {
                            //                 user: null,
                            //             }
                            //         )
                            //         changeUserPlotterForm.modal.openModal()
                            //     }
                            //     : undefined}
                            emailsQuery={
                                {
                                    data: {
                                        data: [
                                            {
                                                email: 'test@email.com',
                                                created_at: new Date(),
                                                id: 0,
                                            },
                                            {
                                                email: 'testing@example.com',
                                                created_at: new Date(),
                                                id: 1,
                                            },
                                        ],
                                        meta: {
                                            current_page: 1,
                                            per_page: 2,
                                            from: 1,
                                            to: 1,
                                            last_page: 1,
                                            total: 2,
                                        },
                                    },
                                    isSuccess: true,
                                    isError: false,
                                    isFetched: true,
                                } as any
                            }
                        />
                    </div>
                </> */}
                {!readonly && (
                    <div className="flex mt-8 pt-5 items-center justify-between">
                        <div className="flex-1"></div>
                        <div className="flex-1 flex items-center justify-end ml-3">
                            <Button variant="secondary" as="link" to="/user">
                                {t('form.cancel')}
                            </Button>
                            <Button
                                type="submit"
                                variant="primary"
                                className="ml-3"
                                loading={isSubmitting}
                            >
                                {t('form.save')}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            {/* {activePlotterForm && (
                <ConfirmModal
                    modal={activePlotterForm.modal}
                    title={t('deactivate.plotter.title')}
                    description={t('deactivate.plotter.description')}
                    actionLabel={t('deactivate.plotter.action')}
                    onSubmit={activePlotterForm.form.submitForm}
                    isSubmitting={activePlotterForm.form.isSubmitting}
                />
            )}
            {changeUserPlotterForm && (
                <ConfirmModal
                    modal={changeUserPlotterForm.modal}
                    title={t('detach.plotter.title')}
                    description={t('detach.plotter.description')}
                    actionLabel={t('detach.plotter.action')}
                    onSubmit={changeUserPlotterForm.form.submitForm}
                    isSubmitting={changeUserPlotterForm.form.isSubmitting}
                />
            )} */}
        </Form>
    )
}
