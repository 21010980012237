export const QUERY_CACHE_PLOTTERS_KEY = 'plotter'
export const QUERY_CACHE_USERS_KEY = 'user'
export const QUERY_CACHE_PLACES_KEY = 'place'
export const QUERY_CACHE_ROLES_KEY = 'role'
export const QUERY_CACHE_STATISTICS_KEY = 'statistics'
export const QUERY_CACHE_STATISTICS_COMPARE_KEY = 'statistics_compare'
export const QUERY_CACHE_REPORTS_KEY = 'report'
export const QUERY_CACHE_DEVICES_KEY = 'device'
export const QUERY_CACHE_PATTERNS_KEY = 'pattern'
export const QUERY_CACHE_BRANDS_KEY = 'brand'
export const QUERY_CACHE_PROFILE_KEY = 'profile'
export const QUERY_CACHE_PROTECTION_FILMS_KEY = 'protection-films'
export const QUERY_CACHE_PROTECTION_FILM_TYPES_KEY = 'protection-film-types'
export const QUERY_CACHE_REPORT_TYPES_KEY = 'report-types'
