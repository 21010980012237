import { useQuery } from 'react-query'
import qs from 'qs'

import client from '../client'
import { QUERY_CACHE_REPORT_TYPES_KEY } from '../../constants/index'

import type { UseQueryOptions, UseQueryResult } from 'react-query'
import type {
    ResponseList,
    ResponseError,
    ReportTypeListResponse,
} from '../types'

export const getReportTypes = <T>(filters?: {}): Promise<T> =>
    client.get(
        '/report-types' + (filters ? '?' + qs.stringify(filters, {}) : '')
    )

export const useReportTypesQuery = <T = ResponseList<ReportTypeListResponse>>(
    filters?: {},
    options?: UseQueryOptions<T, ResponseError>
): UseQueryResult<T, ResponseError> =>
    useQuery<T, ResponseError>(
        [QUERY_CACHE_REPORT_TYPES_KEY, filters],
        () => getReportTypes<T>({ ...filters }),
        options
    )
